var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        dark: "",
        fixed: "",
        app: "",
        value: _vm.navbarShow,
        width: "250",
      },
      on: { input: _vm.stateNavbarShow },
    },
    [
      _vm.navbarLogo
        ? _c(
            "v-app-bar",
            { attrs: { dense: _vm.toolbarDense, dark: "" } },
            [
              _c(
                "v-toolbar-title",
                { staticClass: "text-center" },
                [
                  _c("v-avatar", { attrs: { size: "32px", tile: "" } }, [
                    _c("img", {
                      attrs: {
                        src: "img/icons/android-icon-36x36.png",
                        alt: "VVA",
                      },
                    }),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("toolbar.appname")))]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("the-layout-drawer-list", {
        attrs: {
          dense: _vm.navbarDense,
          routes: _vm.permissionRoutes,
          "icon-show": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
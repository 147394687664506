var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        origin: "center center",
        "nudge-bottom": 10,
        transition: "scale-transition",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { icon: "", large: "", text: "" } }, on),
                [
                  _c(
                    "v-avatar",
                    { attrs: { size: "36px" } },
                    [
                      _vm.avatar
                        ? _c("img", { attrs: { src: _vm.avatar, alt: "" } })
                        : _c(
                            "v-icon",
                            {
                              staticClass: "blue--text",
                              attrs: { "x-large": "" },
                            },
                            [_vm._v(" mdi-account ")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { staticClass: "pa-0" },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-avatar",
                    [
                      _vm.avatar
                        ? _c("img", { attrs: { src: _vm.avatar, alt: "name" } })
                        : _c(
                            "v-icon",
                            {
                              staticClass: "blue--text",
                              attrs: { "x-large": "" },
                            },
                            [_vm._v(" mdi-account ")]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v(_vm._s(_vm.name))]),
                      _c("v-list-item-subtitle", [_vm._v(_vm._s(_vm.user))]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _vm._l(_vm.menuitems, function (item, index) {
            return _c(
              "v-list-item",
              {
                key: index,
                attrs: {
                  to: !item.href ? { name: item.name } : null,
                  href: item.href,
                  ripple: "ripple",
                  disabled: item.disabled,
                  target: item.target,
                  rel: "noopener",
                },
                on: { click: item.click },
              },
              [
                item.icon
                  ? _c(
                      "v-list-item-action",
                      [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(_vm._s(_vm.$t(item.title))),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Layout from '@/views/layout/TheLayout.vue';

const ksfRouter = [
    {
        path: '/',
        hidden: false,
        component: Layout,
        children: [
            {
                path: '/dashboard',
                alias: '/',
                component: () => import('@/views/components/Dashboard/index.vue'),
                name: 'Dashboard',
                meta: {
                    title: 'route.dashboard',
                    icon: 'mdi-view-dashboard',
                    noCache: true,
                    affix: true,
                },
            },
        ],
    },
    {
        path: '/',
        hidden: false,
        component: Layout,
        children: [
            {
                path: '/listevins',
                component: () => import('@/views/components/ListeVins.vue'),
                name: 'ListeVins',
                meta: {
                    title: 'Liste des vins',
                    icon: 'mdi-glass-wine',
                    noCache: true,
                    affix: true,
                },
            },
        ],
    },
    {
        path: '/',
        hidden: false,
        component: Layout,
        children: [
            {
                path: '/documents',
                component: () => import('@/views/components/Documents.vue'),
                name: 'Documents',
                meta: {
                    title: 'Documents',
                    icon: 'mdi-file-document-outline',
                    noCache: true,
                    affix: true,
                },
            },
        ],
    },
    {
        path: '/',
        hidden: true,
        component: Layout,
        children: [
            {
                path: '/creervin',
                component: () => import('@/views/components/CreerVin.vue'),
                name: 'CreerVin',
                meta: {
                    title: 'Créer un vin',
                    icon: ' mdi mdi-plus',
                    noCache: true,
                    affix: true,
                },
            },
        ],
    },
    {
        path: '/',
        hidden: true,
        component: Layout,
        children: [
            {
                path: '/visuetiquettevin/:uuidvin',
                props: true,
                component: () => import('@/views/components/VoirVin.vue'),
                name: 'VoirVin',
                meta: {
                    title: 'Voir un vin',
                    icon: ' mdi mdi-eye',
                    noCache: true,
                    affix: true,
                },
            },
        ],
    },
    // {
    //     path: '/',
    //     hidden: false,
    //     component: Layout,
    //     children: [
    //         {
    //             path: '/visuetiquettevin/:uuidvin',
    //             props: true,
    //             component: () => import('@/views/components/VoirVin.vue'),
    //         },
    //     ],
    // },
    {
        path: '/',
        hidden: false,
        component: Layout,
        children: [
            {
                path: '/boutique',
                component: () => import('@/views/components/Boutique.vue'),
                name: 'Boutique',
                meta: {
                    title: 'Boutique',
                    icon: ' mdi mdi-basket-outline',
                    noCache: true,
                    affix: true,
                },
            },
        ],
    },
    {
        path: '/',
        hidden: true,
        component: Layout,
        children: [
            {
                path: '/profil',
                component: () => import('@/views/components/Profil.vue'),
                name: 'Profil',
                meta: {
                    title: 'Profil',
                    icon: ' mdi mdi-account',
                    noCache: true,
                    affix: true,
                },
            },
        ],
    }
];

export default ksfRouter;

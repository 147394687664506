var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-fab-transition",
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.fab,
              expression: "fab",
            },
            {
              name: "scroll",
              rawName: "v-scroll",
              value: _vm.onScroll,
              expression: "onScroll",
            },
          ],
          attrs: {
            fab: "",
            dark: "",
            fixed: "",
            bottom: "",
            right: "",
            color: "error",
          },
          on: { click: _vm.toTop },
        },
        [_c("v-icon", [_vm._v("mdi-chevron-up")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
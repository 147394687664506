var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    { attrs: { app: "", dense: _vm.toolbarDense } },
    [
      _c(
        "v-app-bar-nav-icon",
        {
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggleNavbar.apply(null, arguments)
            },
          },
        },
        [_c("v-icon", [_vm._v(_vm._s(_vm.toggleNavbarIcon))])],
        1
      ),
      _c("breadcrumbs"),
      _c("v-spacer"),
      _c("full-screen"),
      _c("localization"),
      _c("profile"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
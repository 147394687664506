import route from './route';

export default {
    route,
    toolbar: {
        appname: 'Application',
        settings: 'Paramètres',
        profile: 'Mon compte',
        logout: 'Se déconnecter',
    },
    settings: {
        title: 'Theme Settings',
        default: 'Set Default',
        version: 'Version',
        position: 'Position of elements',
        theme: 'Theme',
        dark: 'Dark theme',
        toolbarDense: 'Toolbar dense',
        navbarDense: 'Navbar dense',
        navbarLogo: 'Navbar logo',
        settingsBtn: 'Setting btn',
        footer: 'Footer',
    },
    login: {
        titleIn: 'Formulaire de connexion',
        titleUn: 'Sign Up Form',
        signIn: 'Se connecter',
        signUp: 'S\'inscrire',
        forgottenPassword: 'Mot de passe oublié',
        name: 'Nom',
        firstname: 'Prénom',
        email: 'Email',
        password: 'Mot de passe',
        confirm: 'Confirmer',
        loginpassvide: 'L\'email et le mot de passe ne peuvent pas être vide.',
        loginpassincorrect: 'L\'adresse électronique ou le mot de passe est incorrect.',
    },
    errors: {
        whoops: 'Oups',
        back: 'Faites-moi sortir d\'ici !',
        301: 'Déplacé de façon permanente',
        401: 'Non autorisé',
        403: 'Interdit',
        404: 'Non trouvé',
        500: 'Erreur interne du serveur',
    },
    guide: {
        description: 'The guide page is useful for some people who entered the project for '
            + 'the first time. You can briefly introduce the features of the project. Demo is based on',
        button: 'Show Guide',
    },
    ui: {
        switch: 'Changer de langue',
        theme: 'Changer de Thème',
        success: 'Succès',
        error: 'Erreur',
        warning: 'Attention',
        info: 'Info',
        primary: 'Primary',
        secondary: 'Secondary',
        default: 'Défaut',
        accent: 'Accent',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'E-mail',
    },
};

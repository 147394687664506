var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    { attrs: { icon: "" }, on: { click: _vm.toggleFullScreen } },
    [_c("v-icon", [_vm._v(_vm._s(_vm.toggleFullScreenIcon))])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-application--wrap" },
    [
      _c("the-layout-drawer"),
      _c("the-layout-toolbar"),
      _c("the-layout-content"),
      _c("the-layout-to-top-fab"),
      _c("the-layout-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.footerShow
    ? _c(
        "v-footer",
        { attrs: { app: "", padless: "", inset: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "secondary text-center",
              attrs: { flat: "", tile: "", width: "100%" },
            },
            [
              _c(
                "v-card-text",
                _vm._l(_vm.icons, function (icon) {
                  return _c(
                    "v-btn",
                    { key: icon, staticClass: "mx-4", attrs: { icon: "" } },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "white--text", attrs: { size: "24px" } },
                        [_vm._v(" " + _vm._s(icon) + " ")]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "white--text" }, [
                _vm._v(" " + _vm._s(new Date().getFullYear()) + " — "),
                _c("strong", [_vm._v("Vue Vuetify Admin")]),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
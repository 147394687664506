export default {
    dashboard: 'Dashboard',
    // introduction: 'Introduction',
    documentation: 'Documentation',
    guide: 'Guide',
    article: 'Article',
    fournisseur: 'Fournisseur',
    calendar: 'Calendar',
    roadmap: 'Roadmap',
    i18n: 'i18n',
    theme: 'Theme',
    // pagePermission: 'Page Permission',
    // rolePermission: 'Role Permission',
    permissions: 'Permission',
    permission: {
        admin: 'Admin',
        editor: 'Editor',
        visitor: 'Visitor',
    },
    components: 'Components',
    component: {
        avatarUpload: 'Avatar Upload',
        backToTop: 'Back To Top',
        countTo: 'Count To',
        dragKanban: 'Drag Kanban',
        dropzone: 'Dropzone',
        jsonEditor: 'Json Editor',
        markdown: 'Markdown',
        splitPane: 'Split Pane',
        sticky: 'Sticky',
        tinymce: 'Tinymce',
    },
    vuetify: 'Vuetify UI',
    vuetifyComponents: {
        components: 'Components',
        alert: 'Alert',
        avatar: 'Avatars',
        badge: 'Badges',
        buttons: 'Buttons',
        calendar: 'Calendar',
        cards: 'Cards',
        carousels: 'Carousels',
        chip: 'Chip',
        colors: 'Colors',
        datepicker: 'Datepicker',
        dialogs: 'Dialogs',
        grid: 'Grid',
        icon: 'Icons',
        pagination: 'Pagination',
        parallax: 'Parallax',
        progress: 'Progress',
        slider: 'Slider',
        snackbar: 'Snackbar',
        tables: 'Tables',
        timepicker: 'Timepicker',
        tooltip: 'Tooltip',
        typography: 'Typography',
    },
    errors: 'Errors',
    errorPages: {
        page301: '301',
        page401: '401',
        page403: '403',
        page404: '404',
        page500: '500',
    },
    charts: 'Charts',
    chart: {
        keyboardChart: 'Keyboard Chart',
        lineChart: 'Line Chart',
        mixChart: 'Mix Chart',
    },
    nested: {
        nested: 'Nested',
        nested1: 'Level 1',
        nested2: 'Level 2',
        nested3: 'Level 3',
    },
    clipboard: 'Clipboard',
    externalLink: 'External Link',
};

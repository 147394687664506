import { render, staticRenderFns } from "./TheLayoutFooter.vue?vue&type=template&id=a99094ea&"
import script from "./TheLayoutFooter.vue?vue&type=script&lang=js&"
export * from "./TheLayoutFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\PROJETS\\VITISSIA\\2024\\vitissiapro\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a99094ea')) {
      api.createRecord('a99094ea', component.options)
    } else {
      api.reload('a99094ea', component.options)
    }
    module.hot.accept("./TheLayoutFooter.vue?vue&type=template&id=a99094ea&", function () {
      api.rerender('a99094ea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/layout/TheLayoutFooter.vue"
export default component.exports
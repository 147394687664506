import Layout from '@/views/layout/TheLayout.vue';

const hiddenRouter = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '/modifiervin/:uuidvin',
                props: true,
                component: () => import('@/views/components/ModifierVin.vue'),
            },
        ],
        hidden: true
    }
];

export default hiddenRouter;

import {
    ChangePassword, loginByEmail, getUserInfo, RecoverPassword
} from '@/api/login';

const user = {
    state: {
        email: '',
        phone: '',
        token: '',
        roles: [],
        user: '',
        name: '',
        firstname: '',
        avatar: '',
        status: '',
    },
    getters: {
        email: (state) => state.email,
        firstname: (state) => state.firstname,
        phone: (state) => state.phone,
        token: (state) => state.token,
        roles: (state) => state.roles,
        name: (state) => state.name,
        user: (state) => state.user,
        avatar: (state) => state.avatar,
        status: (state) => state.status,
        setting: (state) => state.setting,
    },
    mutations: {
        SET_USER_INFO: (state, payload) => {
            if (payload.logout) {
                state.token = '';
                state.roles = [];
                state.user = '';
                state.name = '';
                state.avatar = '';
                state.email = '';
                state.phone = '';
                state.firstname = '';
            } else {
                state.token = payload.token || state.token;
                state.roles = payload.roles || state.roles;
                state.user = payload.user || state.user;
                state.name = payload.name || state.name;
                state.avatar = payload.avatar || state.avatar;
                state.email = payload.email || state.email;
                state.phone = payload.phone || state.phone;
                state.firstname = payload.firstname || state.firstname;
            }
        },
        SET_TOKEN: (state, token) => {
            state.token = token;
        }
    },
    actions: {
        // Login user
        LoginByEmail: async ({ commit, dispatch }, payload) => {
            let response = null;
            try {
                response = await loginByEmail(payload.email.trim(), payload.password, payload.config, payload.axios, payload.resulte, payload.showResult, payload.color);
                console.log('[vuex.user] LoginByEmail', payload, response);
                await commit('SET_TOKEN', response.user.token);
                await commit('SET_USER_INFO', response.user);
                await dispatch('GenerateRoutes', response.user);
            } catch (err) {
                console.warn('[vuex.user] LoginByEmail', err);
            }
            return response;
        },
        ChangePassword: async ({ commit }, payload) => {
            let reponse = '';
            if (payload.newpassword !== '') {
                reponse = await ChangePassword(payload.newpassword, payload.newpasswordcheck, payload.token, payload.config, payload.axios);
            }
            console.log(commit);
            return reponse;
        },
        RecoverPassword: async ({ commit }, payload) => {
            let reponse = '';
            if (payload.email !== '') {
                reponse = await RecoverPassword(payload.email.trim(), '', payload.config, payload.axios);
            }

            if (payload.phone !== '') {
                reponse = await RecoverPassword('', payload.phone.trim(), payload.config, payload.axios);
            }
            await commit('SET_EMAIL', payload.email);
            return reponse;
        },
        GetUserInfo: async ({ commit, state }, payload) => {
            let reponse = null;
            console.log('[vuex.user] GetUserInfo');
            try {
                const response = await getUserInfo(state.token, payload.config, payload.axios);
                if (!response) {
                    throw new Error('Verification failed, please login again.');
                }
                // Verify returned roles are a non-null array
                if (response.user.roles && response.user.roles.length === 0) {
                    throw new Error('getInfo: roles must be a non-null array!');
                }
                reponse = response;
                commit('SET_USER_INFO', response.user);
            } catch (err) {
                console.warn('[vuex.user] GetUserInfo', err);
            }
            return reponse;
        },
        LogOut: async ({ commit }) => {
            try {
                console.log('[vuex.user] LogOut');
                await commit('SET_USER_INFO', { logout: true });
            } catch (err) {
                console.warn('[vuex.user] LogOut', err);
            }
        },
        // Dynamically modify permissions
        /* ChangeRoles: async ({ commit, dispatch }, role) => {
          try {
            console.log('[vuex.user] ChangeRoles', role);
            await commit('SET_TOKEN', role);
            const data = await getUserInfo(role);
            await commit('SET_USER_INFO', data);
            // Redraw the side menu after dynamically modifying the permissions
            await dispatch('GenerateRoutes', data);
          } catch (err) {
            console.warn('[vuex.user] ChangeRoles', err);
          }
        }, */
    },
};

export default user;

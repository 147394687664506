var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        origin: "center center",
        "nudge-bottom": 10,
        transition: "scale-transition",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { icon: "", text: "" } }, on),
                [_c("v-icon", [_vm._v(" mdi-translate ")])],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { staticClass: "pa-0" },
        _vm._l(_vm.locales, function (item, index) {
          return _c(
            "v-list-item",
            {
              key: index,
              attrs: { ripple: "ripple", target: item.target, rel: "noopener" },
              on: {
                click: function ($event) {
                  return _vm.setLocale(item.locale)
                },
              },
            },
            [
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-breadcrumbs", {
    staticClass: "hidden-sm-and-down",
    attrs: { items: _vm.levelList, divider: "/" },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (props) {
          return [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleLink(props.item)
                  },
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.generateTitle(props.item.meta.title)) + " "
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
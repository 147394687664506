var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { staticClass: "layout-drawer", attrs: { dense: _vm.dense } },
    _vm._l(
      _vm.routes.filter((item) => !item.hidden),
      function (item) {
        return _c(
          "div",
          { key: item.title },
          [
            _vm.isVisibleItem(item)
              ? _c(
                  "v-list-item",
                  {
                    attrs: {
                      to: _vm.resolvePath(_vm.onlyOneChild.path),
                      ripple: "ripple",
                    },
                  },
                  [
                    _c(
                      "v-list-item-icon",
                      { staticClass: "layout-drawer__icon" },
                      [
                        _c("v-icon", [
                          _vm._v(_vm._s(_vm.getListIcon(_vm.onlyOneChild))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getListTitle(_vm.onlyOneChild)) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "v-list-group",
                  {
                    attrs: { "prepend-icon": _vm.getListIcon(item) },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function () {
                            return [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " + _vm._s(_vm.getListTitle(item)) + " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("the-layout-drawer-list", {
                      attrs: {
                        dense: _vm.dense,
                        routes: item.children,
                        "base-path": _vm.resolvePath(item.path),
                      },
                    }),
                  ],
                  1
                ),
          ],
          1
        )
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
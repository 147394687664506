const authRouter = [
    {
        path: '/signin',
        alias: '/login',
        component: () => import('@/views/auth/SignIn.vue'),
        hidden: true,
    },
    {
        path: '/signup',
        alias: '/registration',
        component: () => import('@/views/auth/SignUp.vue'),
        hidden: true,
    },
    {
        path: '/registercompany',
        component: () => import('@/views/auth/SocieteSignUp.vue'),
        hidden: true,
    },
    {
        path: '/RecupPassword',
        component: () => import('@/views/auth/RecupPassword.vue'),
        hidden: true,
    },
    {
        path: '/forgottenPassword',
        component: () => import('@/views/auth/ForgottenPassword.vue'),
        hidden: true,
    },
    {
        path: '/ConfirmAccount',
        component: () => import('@/views/auth/ConfirmAccount.vue'),
        hidden: true,
    },
    {
        path: '/visuinfonutricodeqr/:uuidvin',
        props: true,
        component: () => import('@/views/components/VisuinfoCodeQR.vue'),
        hidden: true
    }
];

export default authRouter;

import 'normalize.css/normalize.css';
import 'vuetify/dist/vuetify.min.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';

import { settings } from '@/config';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import vuetify from './plugins/vuetify';
import i18n from './locale';
import App from './App.vue';
import router from './router';
import store from './store';
import './router/permission';
import './registerServiceWorker';

Vue.use(VueAxios, axios);
Vue.use(VueTelInputVuetify, { vuetify });

Vue.prototype.settings = settings;
Vue.prototype.$config = settings;
Vue.prototype.$axios = axios;

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');

import route from './route';

export default {
    route,
    toolbar: {
        appname: 'Application',
        settings: 'Settings',
        profile: 'Profile',
        logout: 'Logout',
    },
    settings: {
        title: 'Theme Settings',
        default: 'Set Default',
        version: 'Version',
        position: 'Position of elements',
        theme: 'Theme',
        dark: 'Dark theme',
        toolbarDense: 'Toolbar dense',
        navbarDense: 'Navbar dense',
        navbarLogo: 'Navbar logo',
        settingsBtn: 'Setting btn',
        footer: 'Footer',
    },
    login: {
        titleIn: 'Sign In Form',
        titleUn: 'Sign Up Form',
        signIn: 'Sign in',
        signUp: 'Sign up',
        email: 'Email',
        forgottenPassword: 'Forgotten Password',
        password: 'Password',
        confirm: 'Confirm',
        name: 'name',
        firstname: 'First name'
    },
    errors: {
        whoops: 'Whoops',
        back: 'Get me out of here!',
        301: 'Moved Permanently',
        401: 'Unauthorized',
        403: 'Forbidden',
        404: 'Not Found',
        500: 'Internal Server Error',
    },
    guide: {
        description: 'The guide page is useful for some people who entered the project for '
            + 'the first time. You can briefly introduce the features of the project. Demo is based on',
        button: 'Show Guide',
    },
    ui: {
        switch: 'Switch Language',
        theme: 'Switch Theme',
        success: 'Success',
        error: 'Error',
        warning: 'Warning',
        info: 'Info',
        primary: 'Primary',
        secondary: 'Secondary',
        default: 'Default',
        accent: 'Accent',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'E-mail',
    },
};

/**
 * Login by email and password
 *
 * @param {String} email user email
 * @param {String} password user password
 * @param {String} resulte
 * @param {boolean} showResult
 * @param {String} color
 */

export const loginByEmail = async (email, password, config, axios) => {
    console.log(`[loginByEmail] email ${email}`);
    let user = {};
    try {
        const url = `${config.API_ENDPOINT}/4DACTION/ksf_sys_web_login`;
        const bodyFormData = new FormData();
        bodyFormData.append('email', email);
        bodyFormData.append('mdp', password);
        await axios.post(url, bodyFormData).then((response) => {
            const result = response.data;
            if (result.header === 'Succès') {
                const urlimageuser = `${config.API_ENDPOINT}/4DACTION/ksf_sys_web_Recu_image_User/${response.data.user.UUID}`;
                user = {
                    token: response.data.user.UUID,
                    name: response.data.user.nom,
                    firstname: response.data.user.prenom,
                    avatar: urlimageuser,
                    roles: ['admin']
                };
            }
        }).catch((error) => {
            console.error('There was an error!', error);
        });

        if (!user || !user.token) {
            throw new Error('User is not found');
        }
    } catch (err) {
        console.warn(`[loginByEmail] ${err}`);
    }
    return { user };
};

export const RecoverPassword = async (email, phone, config, axios) => {
    const url = `${config.API_ENDPOINT}/4DACTION/ksf_sys_web_verifEmail`;
    const bodyFormData = new FormData();
    bodyFormData.append('email', email);
    bodyFormData.append('phone', phone);
    const response = await axios.post(url, bodyFormData);
    return response.data;
};

export const ChangePassword = async (newpassword, newpasswordcheck, token, config, axios) => {
    const url = `${config.API_ENDPOINT}/4DACTION/ksf_sys_web_changemdp`;
    const bodyFormData = new FormData();
    bodyFormData.append('newpassword', newpassword);
    bodyFormData.append('newpasswordcheck', newpasswordcheck);
    bodyFormData.append('token', token);
    const response = await axios.post(url, bodyFormData);
    return response.data;
};

/**
 * Get user information by token
 *
 * @param {String} token user token
 */
export const getUserInfo = async (token, config, axios) => {
    console.log(`[getUserInfo] token ${token}`);
    const user = {};
    try {
        if (!token) {
            throw new Error('Invalid token');
        }

        const url = `${config.API_ENDPOINT}/4DACTION/ksf_sys_web_getuserinfo`;
        const bodyFormData = new FormData();
        bodyFormData.append('token', token);
        const response = await axios.post(url, bodyFormData);
        return response.data;
    } catch (err) {
        console.warn(`[getUserInfo] ${err}`);
    }
    return { user };
};
